@use './variables/variables.scss' as *;

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 5vh;
	font-size: calc(10px + 2vmin);
	padding: 0 20px;
	position: relative;

	@media (min-width: $bp-sm) {
		justify-content: center;
		padding: 0;

		button.logout {
			margin: 40px 40px 0 0;
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}