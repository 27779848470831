@use './variables/variables.scss' as *;

.today-badge {
	display: inline-block;
	background: $gold;
	width: 1em;
	height: 1em;
	text-align: center;
	transform: rotate(20deg);

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		height: inherit;
		width: inherit;
		background: $gold;
		transform: rotate(135deg);
	}
}