@use './variables/variables.scss' as *;

.calendar {
	max-width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0 auto;
	padding: 0 0 40px;
}

.cell {
	display: block;
	position: relative;
	color: $white;
	height: 2em;
	width: calc((100% / 7) - 6px);
	margin: 0 6px 6px 0;
	border-radius: 3px;

	&:not(div) {
	    box-shadow: $boxShadow;
	}

	.today-badge {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 2;
		width: 1.25em;
		height: 1.25em;
    }

	.inner {
		position: relative;
		display: block;
		background: tomato;
		padding: 4px 0 0 4px;
		height: 100%;
		border-radius: inherit;
	    font-size: 0.875em;
	    font-weight: 700;
	}

	&:first-child .inner::before,
	&:nth-child(7n) .inner::before,
	&:nth-child(7n + 1) .inner::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 0.375em;
		background: $white;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
	}

	.day {
		margin: 0 auto;
		padding: 40px 0 20px;
	}

	@media (min-width: $bp-sm) {
		height: 3em;
	}
}

.back-link {
	color: $white;
	font-size: 0.875em;
	font-weight: 700;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		right: calc(100% + 6px);
		height: 6px;
		width: 6px;
		border-bottom: 2px solid $white;
		border-left: 2px solid $white;
		transform: rotate(45deg) translateY(-50%);
	}
}