@use './variables/variables.scss' as *;

.form {
	margin: 0 auto;
}

// Auth Form
.auth-form {
	padding: 20px 20px 40px;
	margin: 0 auto;
	max-width: 100%;

	@media (min-width: $bp-md) {
		padding: 20px 0 40px;
		max-width: 480px;
	}

	.form {
		display: flex;
		flex-direction: column;

		input {
			height: 2em;
		}
	}
}