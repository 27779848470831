@use './variables/variables.scss' as *;

.activity-list {
	.activity-listing {
	    padding: 10px 20px 20px;
	    margin: 10px 0;
	    background: $gold;
	    border-radius: 10px;
	    color: $black;
	    box-shadow: $boxShadow;

	    h4 {
	    	margin: 0.75em 0;
	    }
	}

	.activity-type-badge {
		height: 20px;
		padding: 0 8px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 0.75em;
		font-size: 0.5em;
		font-weight: 700;
		background-color: $white;
		text-transform: uppercase;
		box-shadow: $boxShadow;
	}

	.activity-notes {
		position: relative;
		margin: 1em 0.75em 0;
		padding: 1em 0.5em 0.625em;
		background-color: $goldLight;
		border: 1px dashed $black;

		.activity-notes-label {
			font-size: 0.5em;
			font-weight: 700;
			text-transform: uppercase;
			position: absolute;
			top: 0;
			left: 2px;
		}

		.activity-notes-detail {
			font-family: 'Courier', sans-serif;
			font-size: 0.75em;
		}
	}

	.activities {
		padding: 0 0.75em;

		.event {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid $black;
		}
	}
}