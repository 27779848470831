@use './variables/variables.scss' as *;

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $grey;
    color: $white;
}

main {
	background: rgba(255, 255, 255, 0.25);

    h2 {
        text-align: center;
        margin-top: 0;
    }
}

.container {
	max-width: 100%;
	margin: 0 auto;
    padding: 20px 0;

	@media (min-width: $bp-xs) {
		max-width: 576px;
	}
	
	@media (min-width: $bp-lg) {
		max-width: 992px;
	}
}