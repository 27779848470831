// Colors
$white: #fffafa;
$black: #060606;
$gold: #ffd700;
$goldMedium: #ffef99;
$goldLight: #fffbe6;
$grey: #282c34;

// Breakpoints
$bp-xs: 576px;
$bp-sm: 768px;
$bp-md: 992px;
$bp-lg: 1200px;
$bp-xl: 1400px;

// Misc
$boxShadow: 0px 4px 5px rgba(0, 0, 0, 0.2);